import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {
  seo,
  intro,
  main,
  video,
  stages,
  products,
  industries,
  form,
  buttonBack,
} from '../content/intermodal'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import { useLangContext } from '../context/lang.context'
import Seo from '../components/Seo'
import Main from '../components/Main'
import Video from '../components/Video'
import Stages from '../sections/intermodal/Stages'
import Products from '../sections/intermodal/Products'
// import IndexIndustries from '../sections/IndexIndustries'
import FormMail from '../components/FormMail'
import ButtonForm from '../components/ButtonForm'
import { sButton, sButtonGlow } from '../components/Button'
import { s } from '../style'
import Section from '../components/Section'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Intermodal = () => {
  const { lang } = useLangContext()
  const { image, map } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/intermodal.jpeg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      map: file(
        absolutePath: { regex: "/images/products/intermodal/intermodal_map.png/" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG, formats: WEBP)
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        image={'images/intermodal.jpg'}
        url={seo.url}
      />
      <Intro data={intro} image={image.childImageSharp} logo h={2} />
      <Main data={main} h={1} />
      <Section bottomXS top mobile>
        <GatsbyImage
          image={getImage(map.childImageSharp)}
          alt={'Intermodal Transport - Omida Logistics'}
          objectFit='contain'
        />
      </Section>
      <Video data={video} />
      {/* <IndexIndustries
        include={industries.include}
        title={industries.title[lang]}
        desc={industries.desc[lang]}
      /> */}
      <Stages data={stages} />
      <ButtonForm data={buttonBack} />
      <Products data={products} minimal />
      <FormMail data={form} template='OmidaIntermodal' />
      <div css={{ paddingBottom: '4rem' }} />
      <a href='tel:+48789406656' css={[sButton, sButtonGlow, sCallButton]}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='h-5 w-5'
          viewBox='0 0 20 20'
          fill='currentColor'>
          <path d='M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z' />
        </svg>
      </a>
    </Layout>
  )
}

const sCallButton = {
  cursor: 'pointer',

  position: 'fixed',
  width: 64,
  height: 64,
  bottom: 32,
  left: 32,
  zIndex: 2000,
  marginBottom: 0,
  padding: 0,
  svg: { cursor: 'pointer', width: 32, height: 32 },
  [s.xs]: {
    width: 48,
    height: 48,
    svg: {
      width: 24,
      height: 24,
    },
  },
}

const sMap = {
  [s.xs]: {
    height: '100vw',
  },
}

export default Intermodal
